import { ReactNode } from 'react'

import { NextRouter } from 'next/router'

import { LOCALE } from './Locale'

export * from './Subot'
export * from './Locale'
export type Role =
  | 'user'
  | 'admin'
  | 'local-admin'
  | 'moderator'
  | 'ambassador'
  | 'expert'
  | 'sponsor'
  | 'guide'
  | 'influencer'
export type TogetherRole =
  | 'user'
  | 'admin'
  | 'local-admin'
  | 'moderator'
  | 'ambassador'
  | 'expert'
  | 'sponsor'
  | 'guide'
  | 'influencer'

type ExtendingNextRouter = {
  locale: LOCALE
}
export type ExtendedNextRouter = NextRouter & ExtendingNextRouter

export type LocaleContentAdTagType = {
  result: string
  redundant: boolean
}

export type UserInfo = {
  communities_interacted?: any
  name?: string
  phone?: string
  username?: string
  avatar?: string
  email?: string
  title?: string
  role?: any
  phone_verified?: boolean
  birthday?: string
  gender?: number
  id?: number
  bookmarks?: Array<string | number>
  total_bookmark?: number
  first_time_bookmark?: boolean
  provider?: string
  partnerId?: string
  area_code?: string
  type?: any
}

export type AuthorApi = {
  display_name: string
  avatar: string
  avatar_thumbnail: string
  profile_url: string
  description: string
  meta_tag: string
  slug?: string
  role?: string
  specialty?: string
}

export type ReviewedUserApi = {
  ID?: number
  user_id?: number
  specialty?: string
  permalink?: string
  avatar: string
  avatar_thumbnail: string
  name?: string
  display_name?: string
  profile_url?: string
  description?: string
  type?: string
  role?: string
  slug?: string
  turn_off_profile?: boolean
  post_title?: string
  'care-booking'?: {
    id: number
    type: string
  }
}

export type ReviewedApi = {
  author?: ReviewedUserApi | null
  editor?: ReviewedUserApi | null
  reviewer?: ReviewedUserApi | null
  expert?: ReviewedUserApi | null
  is_author: boolean
  fact_check: ReviewedUserApi | null
}

export type Author = {
  displayName: string
  avatar: string
  profileUrl: string
  description: string
  thumbnailAvatar: string
  metaTag: string
  slug?: string
  role?: string
  specialty?: string
  turn_off_profile?: boolean
  profile_url?: string
  permalink?: string
}

export type CategoryMetaApi = {
  title: string
  description: string
  keyword: string
  url: string
  site_name: string
  locale: string
}

export type ReviewedCategoryApi = {
  post_title: string
  permalink: string
  display_name?: string
  reviewed_date?: string
  profile_url?: string
  avatar_thumbnail?: string
}

export type ReviewedCategory = {
  postTitle: string
  permalink: string
  displayName?: string
  profileUrl?: string
  reviewedDate?: string
  avatarThumbnail?: string
}

export type FAQsApi = {
  title: string
  content: string
}

export type FAQs = {
  title: string
  content: string
}

export type CategoryApi = {
  name: string
  permalink: string
  icon_url: string
  term_id: number
  slug: string
  count: number
  cat_name: string
  category_nicename: string
  description: string
  image: string
  thumbnail: string
  icon: string
  parent: string | number
  child_cates: CategoryApi[]
  color_feature_image: string
  image_color: string
  text_color: string
  meta_tag: string
  cat_parent: {
    name: string
    permalink: string
  }
  is_sponsored?: boolean
  sponsor?: SponsorApi
  reviewed_by?: ReviewedCategoryApi
  reviewed_date?: string
  faqs?: Array<FAQsApi>
  thumnail_color?: string
  customized_url?: string | null
  sponsor_in_infinity?: SponsorApi
}

export type CategoryMeta = {
  title: string
  description: string
  keyword: string
  url: string
  siteName: string
  locale: string
}

export type Category = {
  iconUrl: string
  termId: string | number
  categoryName: string
  name: string
  slugName: string
  description: string
  featureImage: string
  imageColor: string
  displayName: string
  parent: string | number
  permalink: string
  slug: string
  childCates: Category[]
  colorFeatureImage: string
  textColor: string
  metaTag: string
  catParent: {
    name: string
    permalink: string
  }
  isSponsored?: boolean
  sponsor?: Sponsor
  reviewedBy?: ReviewedCategory
  reviewedDate?: string
  faqs?: Array<FAQs>
  thumnailColor?: string
  customizedUrl?: string | null
  sponsorInInfinity?: Sponsor
}

export type BreadcrumbApi = {
  term_id: string | number
  name: string
}

export type Breadcrumb = {
  termId: string | number
  name: string
}

export type SponsorApi = {
  id: string
  title: string
  logo: string
  link: string
}
export type Sponsor = SponsorApi

export type ArticleExpertApi = {
  ID: string | number
  post_title: string
  permalink: string
  specialty: string
  avatar: string
  avatar_thumbnail: string
  name: string
}

export type ArticleExpert = {
  id: string | number
  name: string
  permalink: string
  specialty: string
  avatar: string
  thumbnailAvatar: string
}

export type ArticleApi = {
  ID: string | number
  description: string
  post_name: string
  is_premium?: boolean
  post_title: string
  post_modified: string
  post_date: string
  permalink: string
  template?: 'pregnancy-progression'
  image: string
  image_medium: string
  image_thumbnail: string
  author: AuthorApi
  post_content: string
  categories: Array<CategoryApi>
  video_info?: {
    video_id: string
    video_type: string
  }
  category: CategoryApi
  sections: Array<{
    title: string
    content: string
  }>
  reviewed_by: AuthorApi
  reviewed?: ReviewedApi
  sources: string
  excerpt: string
  modified_time: string
  expert: ArticleExpertApi
  meta_tag?: string
  is_sponsored?: boolean
  sponsor?: SponsorApi
  spotlight?: SpotlightSummaryApi
  key_takeaways?: string
  jumping_link: Array<string>
  breadcrumb?: Array<BreadcrumbApi>
}

export type IExpertPostApi = {
  data: {
    articles: ArticleApi[]
    paging: PagingRecentPostApi
  }
}

export type Article = {
  id: string | number
  description: string
  postName: string
  postContent: string
  isPremium?: boolean
  postTitle: string
  postModified: string
  postDate: string
  permalink: string
  template?: 'pregnancy-progression'
  featureImage: string
  featureImageMobile: string
  featureImageMobileXs: string
  author: Author
  reviewer: Author
  articleReviewed: ReviewedApi
  reviewed: ReviewedApi
  category: Category
  categories: Array<Category>
  sources: string
  relatedArticles?: Array<Article>
  nextArticle?: Article
  relatedCategories?: Array<Category>
  sections: Array<{
    title: string
    content: string
  }>
  excerpt: string
  expert: ArticleExpert
  modifiedTime: string
  metaTag?: string
  isSponsored?: boolean
  sponsor?: Sponsor
  spotlight?: SpotlightSummary | null
  videoInfo?: {
    video_id?: string
    video_type?: string
  } | null
  keyTakeaways: string | null
  jumpingLink: Array<string>
  breadcrumb?: Array<Breadcrumb>
}

//Category Page
export type ExplainersPostApi = {
  posts: Array<ArticleApi>
  posts_per_page: number
  number_page: number
}

export type CommunityEmbedApi = {
  ID: number
  title: string
  guest_title?: string
  guest_desc?: string
  member_title?: string
  member_desc?: string
}

export type CommunityEmbed = {
  id: number
  title: string
  guestTitle: string
  guestDesc: string
  memberTitle: string
  memberDesc: string
}

export type SiteWideBannerApi = {
  content: string
  icon: string
  link: string
}

export type SiteWideBanner = {
  content: string
  icon: string
  link: string
  type: 'article' | 'category' | 'home' | 'other'
}

export type DataCategoryApi = {
  category: CategoryApi
  explainers_posts: Array<ArticleApi>
  sub_categories: Array<CategoryApi>
  experts: ExpertUserSummaryApi[]
  community?: CommunityEmbedApi | null
  sitewide_banner?: SiteWideBannerApi | null
}

export type PageCategoryApi = {
  status: number
  messages: string
  data: DataCategoryApi
}

export type PageCategory = {
  categoryInfo: Category
  explainersPosts: Array<Article>
  subCategories: Array<Category>
  experts: ExpertUserSummary[]
  community: CommunityEmbed | null
  sitewideBanner: SiteWideBanner | null
}
//End Category Page

//Recent Post
export type PagingRecentPostApi = {
  current_page: number
  per_page: number
  found_posts: number
  max_num_pages: number
}

export type PagingRecentPost = {
  currentPage: number
  perPage: number
  foundPosts: number
  maxNumPages: number
}

export type ExpertApi = {
  ID: number
  post_name: string
  post_title: string
  permalink: string
  image: string
  image_thumbnail: string
  post_content: string
  organization_text: string
  specialty: string
  university_degree: string
  experience: string
  medical_license: string
  meta_tag: string
  facebook_url: string
  zalo_url: string
  linkedin_url: string
  twitter_url: string
  instagram_url: string
  email_address: string
  youtube_url: string
  line_url: string
  messenger: string
  whatsapp: string
  google: string
}

export type ExpertUserApi = {
  ID: string
  user_id: string
  display_name: string
  description: string
  profile_url: string
  avatar: string
  avatar_thumbnail: string
  role: string
  user_role: string
  organisation_url?: string
  turn_off_profile?: boolean
  fields: {
    organisation: string
    specialty: string
    university_degree: string
    medical_license: string
    position: string
    personal_email: string
    educations:
      | [
          {
            school_name: string
            study: string
            completed_year: string
          },
        ]
      | null
    sources:
      | [
          {
            title: string
            url: string
          },
        ]
      | null
    experiences:
      | [
          {
            title: string
            organization: string
            duration: string
          },
        ]
      | null
    awards_accreditations:
      | [
          {
            title: string
          },
        ]
      | null
    policies: {
      title: string
      content: string
      readmore: string
      readmore_url: string
    }
    contact: {
      url: string | null
      facebook: string | null
      instagram: string | null
      linkedin: string | null
      myspace: string | null
      pinterest: string | null
      soundcloud: string | null
      tumblr: string | null
      twitter: string | null
      youtube: string | null
      wikipedia: string | null
    }
  }
  specialty: string
  meta_tag: string
  related_post: ArticleApi[]
  articles: ArticleApi[]
  educations?: {
    school_name: string
    study: string
    completed_year: string
  }[]
  awards_accreditations: {
    title: string
  }[]
  experiences: {
    title: string
    organization: string
    duration: string
  }[]
  contact: {
    url: string | null
    facebook: string | null
    instagram: string | null
    linkedin: string | null
    myspace: string | null
    pinterest: string | null
    soundcloud: string | null
    tumblr: string | null
    twitter: string | null
    youtube: string | null
    wikipedia: string | null
  }
}

export type ExpertUser = {
  id: string
  displayName: string
  description: string
  profileUrl: string
  avatar: string
  avatarThumbnail: string
  role: string
  turnOffProfile?: boolean
  organisation_url?: string
  fields: {
    organisation: string
    specialty: string
    university_degree: string
    medical_license: string
    position: string
    personal_email: string
    educations:
      | [
          {
            school_name: string
            study: string
            completed_year: string
          },
        ]
      | null
    sources:
      | [
          {
            title: string
            url: string
          },
        ]
      | null
    experiences:
      | [
          {
            title: string
            organization: string
            duration: string
          },
        ]
      | null
    awards_accreditations:
      | [
          {
            title: string
          },
        ]
      | null
    policies: {
      title: string
      content: string
      readmore: string
      readmore_url: string
    }
    contact: {
      url: string | null
      facebook: string | null
      instagram: string | null
      linkedin: string | null
      myspace: string | null
      pinterest: string | null
      soundcloud: string | null
      tumblr: string | null
      twitter: string | null
      youtube: string | null
      wikipedia: string | null
    }
  }
  metaTag: string
  relatedPost: Article[]
  specialty?: string
  educations?: {
    schoolName: string
    study: string
    completedYear: string
  }[]
  awardsAccreditations?: {
    title: string
  }[]
  experiences?: {
    title: string
    organization: string
    duration: string
  }[]
  contact?: {
    url: string | null
    facebook: string | null
    instagram: string | null
    linkedin: string | null
    myspace: string | null
    pinterest: string | null
    soundcloud: string | null
    tumblr: string | null
    twitter: string | null
    youtube: string | null
    wikipedia: string | null
  } | null
}

export type ExpertUserSummaryApi = {
  ID: string
  user_id: string
  display_name: string
  description: string
  profile_url: string
  avatar: string
  avatar_thumbnail: string
  role: string
  slug?: string
  specialty?: string
  organisation?: string
  organisation_url?: string
  turn_off_profile?: boolean
  permalink?: string
}

export type ExpertUserSummary = {
  id: string
  displayName: string
  description: string
  profileUrl: string
  avatar: string
  avatarThumbnail: string
  role: string
  slug?: string
  specialty?: string
  turnOffProfile?: boolean
  organisation?: string
  organisationUrl?: string
}

export type ExpertHeading = {
  title: string
  content: string
  banner: string
} | null

export type SpotlightPostApi = {
  title: string
  post: ArticleApi[]
  type_view: string
  enable_video: boolean
}

export type ContentSponsor = {
  title: string
  link: string
  logo: string | false
  image: string | false
}

export type SpotlightBannerApi = {
  desktop?: string
  mobile?: string
  small?: string
  items?: SpotlightSuperContentBannerApi[]
  url?: string
}

export type SpotlightSuperContentBannerApi = {
  title: string
  description: string
  position: 'top' | 'bottom'
  background: string
  mobile_banner?: string | false | null
}
export type SpotlightSuperContentBanner = {
  title: string
  description: string
  position: 'top' | 'bottom'
  banner: string
  desktopBanner: string
}

export type SpotlightVideos = {
  title: string
  videos: {
    video: string
  }[]
}

export type SpotlightTemplate = 'default' | 'super_content' | 'landing'

export type SingleSubotApi = {
  title: string
  english_name: string
  icon: string
  subot_url: string
}

export type SpotlightSubotApi = {
  title: string
  post: Array<SingleSubotApi>
}

export type SpotlightExpertApi = {
  user_id: string
  display_name: string
  profile_url: string
  avatar_thumbnail: string
  role: string
  former_role: string
  specialty: string
  organisation: string
  organisation_url: string
  turn_off_profile: boolean
}

export type SpotlightApi = {
  ID: number
  post_name: string
  post_title: string
  permalink: string
  start_date?: string | null
  end_date?: string | null
  sponsor: ContentSponsor[]
  banner: {
    desktop?: string
    mobile?: string
    small?: string
    items?: SpotlightSuperContentBannerApi[]
  }
  posts: SpotlightPostApi[]
  community?: CommunityEmbedApi | null
  health_tool: string
  videos: SpotlightVideos
  expert: {
    title: string
    experts: Array<SpotlightExpertApi>
  }
  meta_tag: string
  is_sponsor?: boolean
  type?: SpotlightTemplate
  content?: string
  sources?: string
  translate?: { [key: string]: string }
  faqs?: {
    title: string
    data: Array<{
      question: string
      answer: string
      readmore: {
        post_title: string
        permalink: string
      }
    }>
  }
  subot: SpotlightSubotApi
  image: string
  image_medium: string
  image_thumbnail: string
  workshop?: {
    title: string
    description: string
    sections: WorkshopApi[]
  }
  gaming?: {
    game: GameApi
    mechanism: {
      title: string
      description: string
      steps: MechanismStep[]
    }
    reward: {
      image: string
      items: RewardItems[]
    }
  }
  community_title?: string
}

export type DataRecentPostApi = {
  articles: Array<ArticleApi>
  posts: Array<ArticleApi>
  paging: PagingRecentPostApi
}

export type RecentPostApi = {
  status: number
  messages: string
  data: DataRecentPostApi
}

export type RecentPost = {
  articles: Array<Article>
  posts: Array<Article>
  paging: PagingRecentPost
}
//End Recent Post

export type SearchQuery = {
  s: string
  // date: string
  // category_id: string | number
  page: string | number
}

export type outline = { link: string; tag: string; id: string }

export type StaticPageApi = {
  ID: number
  post_title: string
  modified_time: string
  feature_image: string
  post_content: string
  outlines_link?: outline[]
}

export type StaticPage = {
  id: number
  postTitle: string
  modifiedTime: string
  featureImage: string
  postContent: string
  outlinesLink: outline[]
}

export type HealthTool = {
  title: string
  subtitle: string
  icon: string
  link: string
  template: string
  id: string
}

export type HealthToolByCategory = {
  termId: number
  name: string
  healthTools: HealthTool[]
}

export type CommunityApi = {
  name?: string
  community_id: number
  id?: number
  community_slug: string
  count_members: number
  count_posts: number
  count_topics: number
  description: string
  joined: boolean
  thumbnail: string
  title: string
  cover_image?: string
  mobile_cover_image?: string
  is_active?: number | boolean
  slug?: string
}

export type CommunityBanner = {
  image: string
  mobile_image: string
}

export type HelloHealthGroupCommunityApi = {
  id: number
  slug: string
  name: string
  description: string
  joined: 0 | 1
  is_active: 0 | 1
  topics_count: number
  posts_count: number
  members_count: number
  thumbnail: string
  cover_image: string
  mobile_cover_image: string
  banner: CommunityBanner[]
}

export type TogetherTopicsApi = {
  community_id: number
  community_slug: string
  count_members: number
  count_posts: number
  count_topics: number
  description: string
  joined?: boolean
  thumbnail: string
  title: string
  topic_id: number
  topic_slug: string
  is_active: boolean
}

export type AboutUs = {
  hero_section: {
    title: string
    content: string
    images: { image: string }[]
  }
  our_vision: {
    icon: string
    title: string
    content: string
  }
  our_mission: {
    icon: string
    title: string
    content: string
  }
  quotation: {
    content: string
    avatar: string
    name: string
    title: string
  }
  our_values: {
    image: string
    title: string
    values: {
      title: string
      content: string
    }[]
  }
  our_services: {
    title: string
    services: {
      icon: string
      title: string
      content: string
      cta: string
      cta_url: string
    }[]
  }
  our_expert: {
    title: string
    content: string
    cta: string
    cta_url: string
    experts: {
      ID: string
      name: string
      specialty: string
      permalink: string
      avatar: string
      avatar_thumbnail: string
    }[]
  }
  quality_control: {
    title: string
    content: string
    quality_card: { image: string; title: string; content: string }[]
  }

  policy: {
    title: string
    sections: {
      title: string
      content: string
      cta: string
      cta_url: string
    }[]
  }
  our_team: {
    title: string
    content: string
    members: {
      avatar: string
      name: string
      title: string
      description: string
      linkedin: string
    }[]
  }
  recruitment: {
    background: string
    title: string
    icon: string
    cta: string
    cta_url: string
  }
  about: {
    title: string
    content: string
    cta: string
    cta_url: string
  }
  contact: {
    title: string
    content: string
    info: { title: string; info: string }[]
  }
}

export type Specialty = {
  name: string
  id: number
  thumbnail: string
  link: string
}

export type HealthToolApi = {
  title: string
  subtitle: string
  icon: string
  link: string
  template: string
  id: string
}
export type CategoryHealthToolApi = {
  term_id: number
  name: string
  health_tools: HealthToolApi[]
}

export type DesktopMenuPopup =
  | 'categories'
  | 'tools'
  | 'profile'
  | 'together'
  | 'none'

export type MobileMenuPopup = 'categories' | 'profile' | 'none'

export type LocaleType =
  | 'vi-VN'
  | 'id-ID'
  | 'ms-MY'
  | 'km-KH'
  | 'vi-VN'
  | 'th-TH'
  | 'my-MM'
  | 'zh-TW'
  | 'hi-IN'

export type LogoType =
  | 'hellobacsi'
  | 'hellosehat'
  | 'hellohealth'
  | 'hellotogether'
  | 'hellocare'
  | 'logomark'
  | 'hellodoctor'
  | 'hellokrupet'
  | 'helloswasthya'
  | 'hellosayarwon'
  | 'helloyishi'
  | 'hellokhunmor'
  | 'hellodoktor'

export type Expert = {
  id: number
  postName: string
  postTitle: string
  permalink: string
  image: string
  imageThumbnail: string
  postContent: string
  organizationText: string
  specialty: string
  universityDegree: string
  experience: string
  medicalLicence: string
  metaTag: string
  facebookUrl: string
  zaloUrl: string
  linkedInUrl: string
  twitterUrl: string
  instagramUrl: string
  emailAddress: string
  youtubeUrl: string
  lineUrl: string
  messenger: string
  whatsApp: string
  google: string
}

export type SpotlightPost = {
  title: string
  post: Article[]
  type_view: string
  enableVideo: boolean
}

export type SpotlightBanner = {
  desktop?: string
  mobile?: string
  small?: string
  items?: SpotlightSuperContentBanner[]
  url?: string
}

export type Spotlight = {
  id: number
  postName: string
  postTitle: string
  permalink: string
  startDate?: string | null
  endDate?: string | null
  sponsor: ContentSponsor[]
  banner: SpotlightPageBanner | SpotlightLandingPageBanner
  community?: CommunityEmbed | null
  posts: SpotlightPost[]
  healthTool: string | null
  videos: SpotlightVideos | null
  metaTag: string
  isSponsor?: boolean
  type: SpotlightTemplate
  content: string
  sources: string
  faqs: Array<FAQs>
  faqsTitle: string
  image?: string
  imageMedium?: string
  imageThumbnail?: string
  workshop?: {
    title: string
    description: string
    sections: Workshop[]
  } | null
  gaming?: {
    game: Game
    mechanism: {
      title: string
      description: string
      steps: MechanismStep[]
    }
    reward: {
      image: string
      items: RewardItems[]
    }
  } | null
  expert: {
    title: string
    experts: Array<SpotlightExpert>
  }
  communityTitle: string
}

export type MetaTagApi = {
  title: string
  description: string
  locale: string
  url: string
  site_name: string
  published_time: string
  modified_time: string
  updated_time: string
  image: string
  width: number
  height: number
  open_graph: {
    title: string
    description: string
    image: string
    image_width: number
    image_height: number
  }
  twitter: {
    title: string
    description: string
    image: string
  }
  tags: Array<{
    name: string
    slug: string
  }>
}
export type MetaTag = {
  title: string
  description: string
  locale: string
  url: string
  siteName: string
  publishedTime: string
  modifiedTime: string
  updatedTime: string
  image: string
  width: number
  height: number
  openGraph: {
    title: string
    description: string
    image: string
    imageWidth: number
    imageHeight: number
  }
  twitter: {
    title: string
    description: string
    image: string
  }
  tags: Array<{
    name: string
    slug: string
  }>
}

export type DrugItemApi = {
  title: string
  category_id: string
  permalink: string
}

export type MedicalReviewItemApi = {
  title: string
  description: string
  image_url: string
}

export type MedicalReviewApi = {
  title: string
  description: string
  items: MedicalReviewItemApi[]
}

export type MedicalReviewItem = {
  title: string
  description: string
  imageUrl: string
}

export type MedicalReview = {
  title: string
  description: string
  items: MedicalReviewItem[]
}

export type CategoriesLayout = 'short' | 'long'

export type BmiObjective = 'lose-weight' | 'maintain-weight' | 'gain-weight'
export type BmiInfo = {
  age: string
  height: string
  weight: string
  objective: BmiObjective
}

export type BmrObjective = 'lose-fat' | 'maintain-weight' | 'build-muscle'
export type BmrActiveLevel =
  | 'sedentary'
  | 'lightly-active'
  | 'moderately-active'
  | 'intensely-active'
  | 'extremely-active'

export type BmrInfo = {
  age: string
  height: string
  weight: string
  objective: BmrObjective
  activeLevel: BmrActiveLevel
}

export type DataExpertResApi = {
  experts: Array<ExpertApi>
  paging: PagingRecentPostApi
}

export type ExpertResApi = {
  status: number
  messages: string
  data: DataExpertResApi
}

export type BMICondition =
  | 'underweight'
  | 'healthy'
  | 'overweight'
  | 'obese1'
  | 'obese2'

export type BMIResultApi = {
  comparison: string
  condition: string
  risk: string
  suggestion: string
  faqs: { [x: string]: string }[]
  illustrator: string
  suggested_articles: ArticleApi[]
  status: string
  chart_data: { bmi: string; percent: number }[]
  disclaimer: string
  suggested_articles_link: string
}

export type BMIResult = {
  comparison: string
  assessmentData: { [tab: string]: string }[]
  faqs: { [x: string]: string }[]
  illustrator: string
  suggestedArticles: Article[]
  status: BMICondition
  chartData: { bmi: string; percent: number }[]
  disclaimer: string
  suggestedArticlesLink: string
}

export type ToolContentApi = {
  information: {
    title: string
    description: string
  }[]
  reviewed_by: ReviewedUserApi
  reviewed_date: string
  meta_tag: string
}

export type ToolContent = {
  information: {
    title: string
    description: string
  }[]
  reviewBy: {
    reviewer: string
    date: string
    link: string
  }
  metaTag: string
}

export type OvulationInfoApi = {
  disclaimer: string
  info: {
    title: string
    description: string
  }[]
  reviewed_by: ReviewedUserApi
  reviewed_date: string
  meta_tag?: string
  embeb: {
    pregnacy_navigation: Array<CategoryApi>
  }
}
export type OvulationInfo = {
  disclaimer: string
  info: {
    title: string
    description: string
  }[]
  reviewedBy: {
    id: number | string
    permalink: string
    name: string
    postTitle: string
  }
  reviewedDate: string
}

export type OvulationSuggestedArticleApi = {
  suggested_articles: ArticleApi[]
  suggested_articles_link: string
}

export type OvulationSuggestedArticle = {
  suggestedArticles: Article[]
  suggestedArticlesLink: string
}

export type Reaction = {
  id: string
  reacter: {
    userID: string
    username: string
  }
}

export type ReactionsConnection = {
  nodes: Reaction[]
}

export type WelcomeFeatureApi = {
  name: string
  description: string
  icon: string
}

export type UrlSource = 'first_login' | 'welcome_back' | 'comment'

export type ChatBotNodeIntent = {
  id: string
  type: string
  label: string
  key: string
  url: string
  score?: number
}

export type ChatBotNodeAction = {
  id: string
  type: string
  label: string
  url: string
  key: `${string}` | `${string}.${string}` | `${string}.${number}`
}

export type ChatBotNodeFormSubmitMeta = {
  key: string
  control: string
  value: string
}

export type ChatBotNodeFormSubmitData = {
  action_id: string
  action_value: string
  form_submit_meta: Array<ChatBotNodeFormSubmitMeta>
}

export type ChatBotNode = {
  id: string
  position: number
  type: number
  name?: string
  intents: Array<ChatBotNodeIntent>
  actions: Array<ChatBotNodeAction>
  current_score?: number
}

export type ChatBotFirstNode = {
  id: string
  icon: string
  landing_page_url_prefix: string
  landing_page_url_slug: string
  landing_page_title: string
  landing_page_seo_title: string
  landing_page_image: string
  info: Array<{ title: string; description: string }>
  landing_page_descriptions: Array<string>
  landing_page_reviewer: string
  landing_page_review_date: string
  landing_page_disclaimer: string
  first_node: ChatBotNode
  country_code: string
  landing_page_description: string
  landing_page_meta_description: string
  landing_page_icon: string
  landing_page_info: Array<{ title: string; description: string }>
  flow_name: string
}

export type ChatBotNodes = {
  node: ChatBotNode
  current_score?: number
}

export type ChatBotResultItem = {
  id: string
  type: string
  label: string
  url: string
  button?: ChatBotResultItem
}

export type ChatBotResult = {
  intents: Array<ChatBotResultItem>
}

export type ChatBotResultMetaItem = {
  key: string
  value: string
}

export type ChatBotResultFetchUrl = {
  url: string
  title: string
  thumbnail: string
  description?: string
  type: number
  meta: Array<ChatBotResultMetaItem>
}

export type ChatBotResultItemWithInfo = {
  id: string
  type: string
  label: string
  url: string
  button?: ChatBotResultItem
  info?: ChatBotResultFetchUrl
}

export type ChatBotRequest = {
  node_id: string
  cookie_id: string
  window_id: string
  url: string
  title_url: string
  action_id: string
  action_value: string
  ga_client_id: string
  current_score: string | number
  source?: string
}

export type SpotlightSummaryApi = {
  ID: number
  name: string
  permalink: string
  image?: string
  image_medium?: string
  image_thumbnail?: string
  start_date?: string | null
  end_date?: string | null
  small_banner?: string
  desktop_banner?: string | null
  mobile_banner?: string | null
}

export type SpotlightSummary = {
  id: number
  name: string
  permalink: string
  image: string
  imageMedium?: string | null
  imageThumbnail?: string | null
  startDate?: string | null
  endDate?: string | null
  desktopBanner?: string | null
  mobileBanner?: string | null
}

export type WeekOfPregnancy = {
  ID: number
  name: string
  permalink: string
  fruit_icon?: string | null
  icon?: string | null
  height?: string | null
  weight?: string | null
  description?: string | null
}

export type WeekOfPregnancyV2 = {
  id: number
  name: string
  permalink?: string
  fruit_icon?: string | null
  icon?: string | null
  height?: string | null
  weight?: string | null
  description: string
  cleanDescription: string
  fruitName?: string
}

export type FooterLink = {
  title: string
  link: string
}

export type DropdownTopic = {
  title: string
  icon: string
  color: string
  description: string
  health_tool?: {
    title: string
    permalink: string
    icon?: string
  } | null
  article_url: string
}

export type HelloHealthGroupCommunityPostTopicApi = {
  id: number
  slug: string
  name: string
  description: string
}
export type HelloHealthGroupCommunityPostApi = {
  id: number
  slug: string
  name: string
  description: string
  likes_count: number
  comments_count: number
  shares_count?: number
  images: string[] | null
  is_pin: 0 | 1
  pin_date: string | null
  post_type: string
  schedule_datetime: string | null
  is_active: 0 | 1
  preview_link: string | null
  preview_title: string | null
  preview_description: string | null
  preview_image: string | null
  created_at: string
  created_time: string
  bookmarked: boolean
  liked: boolean
  user: {
    id: number
    partner_id: number
    role: 'moderator' | 'member' | string | null
    type: string
    user_name: string
    name: string
    email: string
    avatar: string
  } | null
  // mentions: []
  community: {
    id: number
    slug: string
    name: string
    description: string
  }
  topics: HelloHealthGroupCommunityPostTopicApi[]
}

export type HomePageCommunityPostTopicsApi = {
  topic_id: string
  topic_name: string
  topic_slug: string
}
export type HomePageCommunityPostApi = {
  author_avatar: string
  author_full_name: string
  title: string
  community_name: string
  community_slug: string
  preview_image: string | null
  count_likes: number
  count_comments: number
  count_shares: number
  created_at: string
  images: string[] | null
  preview_description: string | null
  preview_link: string | null
  description: string
  liked: boolean
  post_id: number
  slug: string
  created_time: string
  topics: HomePageCommunityPostTopicsApi[]

  deleted_at: string | null
  schedule_datetime: string | null
  moderator_avatar: string | null
  moderator_id: number
  moderator_name: string | null
  created_moderator_id: number
  user_email: string
  is_active: number
  author_name: string
  mention?: TogetherPostMention[]
}

export type AnchorRef = {
  label: string
  id: string
}

export type TableOfContentItem = ReactNode | string | AnchorRef

export type AffiliateChannel = {
  name: string
  icon: string
  url: string
  price: string
  promotion: {
    code: string
    description: string
    discount: string
    name: string
    end_date: string
  }
}

export type AffiliateCurrency = {
  code: string
  symbol: string
}

export type AffiliateProductCategoryApi = {
  name: string
  permalink: string
  term_id: number
  text_color: string
}

export type AffiliateProductCategory = {
  name: string
  permalink: string
  termId: number
  textColor: string
}

export type AffiliateProductBrandApi = AffiliateProductCategoryApi

export type AffiliateProductBrand = AffiliateProductCategory

export type AffiliateProductApi = {
  ID: number
  product_sku: string | null
  post_name: string | null
  post_title: string | null
  permalink: string | null
  brand: AffiliateProductBrandApi | null
  product_cat: AffiliateProductCategoryApi[] | null
  image: string | false
  image_medium: string | false
  image_thumbnail: string | false
  gallery: string[]
  channels: AffiliateChannel[]
  currency_unit: AffiliateCurrency
}

export type AffiliateProduct = {
  id: number
  sku: string
  postName: string
  postTitle: string
  permalink: string
  brand: AffiliateProductBrand[]
  productCat: AffiliateProductCategory[]
  image: string
  imageMedium: string
  imageThumbnail: string
  gallery: string[]
  channel: AffiliateChannel[]
  currencyUnit: AffiliateCurrency
  priceRange: string
}

export type ArticleProps = {
  url?: string
  title?: string
  description?: string
  thumbnail?: string
  type?: number
  meta?: Array<{ [key: string]: string }>
}

export type TogetherCommunityApi = {
  name?: string
  id: number
  members_count: number
  posts_count: number
  topics_count: number
  description: string
  joined: boolean
  thumbnail: string
  cover_image?: string
  mobile_cover_image?: string
  is_active?: number | boolean
  slug?: string
  banner: CommunityBanner[]
}

export type TogetherCommunity = {
  name?: string
  id: number
  membersCount: number
  postsCount: number
  topicsCount: number
  description: string
  joined: boolean
  thumbnail: string
  coverImage?: string
  mobileCoverImage?: string
  isActive?: number | boolean
  slug?: string
}

export type TogetherTopicApi = {
  categories: any[]
  community: {
    description: string
    name: string
    id: number
    slug: string
  }
  count_members: number
  count_posts: number
  description: string
  id: number
  joined?: boolean
  thumbnail: string
  is_active: number
  name: string
  order: number
  slug: string
}

export type TogetherTopic = {
  categories: any[]
  community: {
    description: string
    name: string
    id: number
    slug: string
  }
  countMembers: number
  countPosts: number
  description: string
  id: number
  joined?: boolean
  thumbnail: string
  isActive: boolean
  name: string
  order: number
  slug: string
}

export type TogetherPostMention = {
  id: number
  name: string
}

export type TogetherPostApi = {
  bookmarked: boolean
  comments: any[]
  comments_count: number
  community: {
    description: string
    name: string
    id: number
    slug: string
  }
  likes_count: number
  created_at: string
  created_time: string
  description: string
  id: number
  images: string[]
  is_active: number
  is_pin: number
  pin_date: string
  post_type: string
  name: string
  liked?: boolean
  preview_description: string
  preview_image: string
  preview_link: string
  preview_title: string
  schedule_datetime: string
  slug: string
  topics: {
    description: string
    name: string
    id: number
    slug: string
  }[]
  user: {
    avatar: string
    email: string
    id: number
    name: string
    partner_id: number
    role: string
    type: string
    user_name: string
  }
  mention?: TogetherPostMention[]
}

export type TogetherPost = {
  bookmarked: boolean
  comments: any[]
  commentsCount: number
  community: {
    description: string
    name: string
    id: number
    slug: string
  }
  likesCount: number
  createdAt: string
  createdTime: string
  description: string
  id: number
  images: string[]
  isActive: number
  isPin: number
  pinDate: string
  postType: string
  name: string
  liked?: boolean
  previewDescription: string
  previewImage: string
  previewLink: string
  previewTitle: string
  scheduleDatetime: string
  slug: string
  topics: {
    description: string
    name: string
    id: number
    slug: string
  }[]
  user: {
    avatar: string
    email: string
    id: number
    name: string
    partnerId: number
    role: string
    type: string
    userName: string
  }
  mention?: TogetherPostMention[]
}

export type OvulationLocalStoredType = {
  cycle: number
  periodDateStart: Date
  periodLength: number
}

export type LeadGenInfo = {
  is_leadgen: boolean
  leadgen_data: {
    campaign_code: string
    image_desktop: string
    image_mobile: string
    title?: string
    desc?: string
    question: string
    answers?: {
      answer: string
    }[]
    'consent-opts'?: string
    submit_title?: string
  }
}

export type BabyVaccinationRecord = {
  id: string
  vaccineId: string
  vaccineName: string
  vaccineAntigen: string
  givenOn: string
  doseNumber: number
  totalDoses: number
  status: BabyVaccinationStatus
  optional: boolean
}

export type BabyVaccinationDetail = {
  profile: {
    id: string
    gender: 'male' | 'female'
    name: string
    dob: string
    new: boolean
  }
  timelines: {
    id: string
    name: string
    shortName: string
    fromDay: number
    toDay: number
    records: BabyVaccinationRecord[]
  }[]
  notifications: {
    id: string
    name: string
    shortName: string
    records: BabyVaccinationRecord[]
  }[]
}

export type BabyInfo = {
  age: string
  gender: string
  name: string
}
export type BabyVaccinationInfoApi = {
  disclaimer: string
  info: {
    title: string
    description: string
  }[]
  reviewed_by: ReviewedUserApi
  reviewed_date: string
  meta_tag?: string
}

export type BabyVaccinationInfo = {
  disclaimer: string
  info: {
    title: string
    description: string
  }[]
  reviewedBy: ReviewedUserApi
  reviewedDate: string
  metaTag?: string
}
export type BabyVaccinationRecordsApi = {
  id: string
  vaccine_id: string
  vaccine_name: string
  vaccine_antigen: string
  given_on: string
  dose_number: number
  total_doses: number
  status: boolean
  vaccine_optional: boolean
}

export type BabyVaccinationStatus = 'vaccinated' | 'passed' | 'next'

export type BabyVaccinationDetailApi = {
  profile: {
    id: string
    gender: boolean
    name: string
    dob: string
    new: boolean
  }
  timelines: {
    id: string
    name: string
    short_name: string
    from_day: number
    to_day: number
    records: BabyVaccinationRecordsApi[]
  }[]
  notifications: {
    id: string
    name: string
    short_name: string
    records: BabyVaccinationRecordsApi[]
  }[]
}

export type InfoBabyVaccineResultPageApi = {
  faqs: { [x: string]: string }[]
  suggested_articles: ArticleApi[]
  suggested_articles_link: string
}

export type InfoBabyVaccineResultPage = {
  faqs: { [x: string]: string }[]
  suggestedArticles: Article[]
  suggestedArticlesLink: string
}

export type BabyVaccinationMainTimelinesApi = {
  id: string
  name: string
  vaccines: {
    id: string
    name: string
    antigen: string
    total_doses: number
    optional: boolean
    records: BabyVaccinationRecordsApi[]
  }[]
  timelines: {
    id: string
    name: string
    short_name: string
  }[]
}
export type BabyVaccinationMainTimelines = {
  id: string
  name: string
  vaccines: {
    id: string
    name: string
    antigen: string
    totalDoses: number
    optional: boolean
    records: BabyVaccinationRecord[]
  }[]
  timelines: {
    id: string
    name: string
    shortName: string
  }[]
}
export type BabyVaccinationVaccineInfoApi = {
  id: string
  name: string
  antigen: string
  brand_img: string
  benefits: string
  link: string
  contraindications: string
  optional: boolean
  detail: string
  records: BabyVaccinationRecordsApi[]
}

export type BabyVaccinationVaccineInfo = {
  id: string
  name: string
  antigen: string
  brandImg: string
  benefits: string
  link: string
  contraindications: string
  optional: boolean
  detail: string
  records: BabyVaccinationRecord[]
}

export type OvulationContentApi = {
  post_title: string
  post_content: string
  information: Array<{
    title: string
    description: string
  }>
  informations: [
    {
      title: string
      description: string
    },
  ]
  faqs?: {
    [key: string]: [
      {
        title: string
        content: string
      },
    ]
  }
  reviewed_by: ReviewedUserApi
  reviewed_date: string
  meta_tag: string
  title: string
  description: string
  disclaimer: string
  is_sponsored: boolean
  sponsor: ContentSponsor
  featured_image: string
}
export type OvulationContent = {
  information: Array<{
    title: string
    description: string
  }>
  reviewBy: {
    reviewer: string
    date: string
    link: string
  }
  metaTag: string
  title: string
  description: string
  disclaimer: string
  is_sponsored: boolean
  sponsor: ContentSponsor
  featuredImage: string
}

export type DueDateInfo = {
  post_title: string
  post_content: string
  disclaimer: string
  featured_image: string
  informations: [
    {
      title: string
      description: string
    },
  ]
  faqs: {
    [key: string]: [
      {
        title: string
        content: string
      },
    ]
  }
  reviewed_by: {
    ID: number | string
    name: string
    post_title: string
    permalink: string
    slug?: string
    role?: string
    user_id: number
    display_name?: string
    profile_url?: string
  }
  reviewed_date: string
}
export type TogetherPostApiV2 = {
  bookmarked: boolean
  commented: boolean
  comments_count: number
  community: {
    description: string
    name: string
    id: number
    slug: string
  }
  likes_count: number
  created_at: string
  created_time: string
  description: string
  id: number
  images: string[]
  is_active: number
  is_pin: number
  pin_date: string
  post_type: string
  name: string
  liked?: boolean
  preview_description: string
  preview_image: string
  preview_link: string
  preview_title: string
  schedule_datetime: string
  slug: string
  is_anonymous: number
  topics: {
    description: string
    name: string
    id: number
    slug: string
  }[]
  user: {
    avatar: string
    email: string
    id: number
    name: string
    partner_id: number
    role: string
    type: string
    user_name: string
  }
  mentions?: {
    id: number
    name: string
  }[]
}
export type TogetherPostV2 = {
  bookmarked: boolean
  commented: boolean
  commentsCount: number
  community: {
    description: string
    name: string
    id: number
    slug: string
  }
  likesCount: number
  createdAt: string
  createdTime: string
  description: string
  id: number
  images: string[]
  isActive: number
  isPin: boolean
  pinDate: string
  postType: string
  title: string
  liked?: boolean
  previewUrl?: {
    title: string
    url: string
    image?: string
    description?: string
  } | null
  scheduleDatetime: string
  slug: string
  topics: {
    description: string
    name: string
    id: number
    slug: string
  }[]
  author: {
    avatar: string
    email: string
    id: number
    name: string
    partnerId: number
    role: string
    type: string
    username: string
  }
  mentions?: {
    id: number
    name: string
  }[]
  isAnonymous: boolean
}
export type PWGCondition = 'underweight' | 'healthy' | 'overweight'

export type PwgInfoApi = {
  disclaimer: string
  featured_image: string
  info: {
    title: string
    description: string
  }[]
  reviewed_by: ReviewedUserApi
  reviewed_date: string
  meta_tag?: string
  pregnacy_navigation: Array<CategoryApi>
  is_leadgen?: boolean
  is_skippable?: boolean
  sponsor?: { title: string; link: string; logo: string }
  is_sponsored?: boolean
}

export type PwgInfo = {
  disclaimer: string
  featuredImage: string
  info: {
    title: string
    description: string
  }[]
  reviewedBy: ReviewedUserApi
  reviewedDate: string
  metaTag?: string
  pregnancyNavigation: Array<CategoryApi>
  isLeadgen?: boolean
  isSkippable?: boolean
  isSponsored?: boolean
  sponsor?: { title: string; link: string; logo: string }
}
export type PregWeightInfo = {
  yourSelf: string
  week: string
  weightBefore: string
  height: string
  weight: string
  twins: string
}

export type PregnancyWeightLocalStoredType = {
  yourSelf?: string
  pwg: number
  bmi: number
  week: number
  twin: boolean
  weightBefore: number
  weight: number
  height: number
}

export type PWGResultApi = {
  suggestion: string
  faqs: { title: string; description: string }[]
  disclaimer: string
  suggested_articles: ArticleApi[]
  suggested_articles_link: string
  explaination: string
  weight_by_week: { week: number; min: number; max: number }[]
  care: string | any[]
  embeb: {
    pregnacy_navigation: Array<CategoryApi>
  }
  status: PWGCondition
}

export type PWGResult = {
  assessmentData: { [tab: string]: string }[]
  faqs: { title: string; description: string }[]
  disclaimer: string
  suggestedArticles: Article[]
  suggestedArticlesLink: string
  weightByWeek: { week: number; min: number; max: number }[]
  care: string | any[]
  pregnancyNavigation: Array<CategoryApi>
  status: PWGCondition
}
export type PwgResult =
  | 'Athlete'
  | 'Excellent'
  | 'Good'
  | 'Average'
  | 'Poor'
  | 'Normal'
  | 'Abnormal'

export type DropdownOption<V> = {
  label: string
  value: V
}

export type RecordChartType = 'day' | 'month' | 'year'

export type RecordChartByMonthOption = '3-month' | '6-month' | '12-month'
export type RecordChartByYearOption = '1-year' | '2-year' | '3-year'

export type WeekOfPregnancyApi = {
  title: string
  content: string
  clean_content: string
  fruit_icon: string
  icon: string
  height: string
  weight: string
  suggested_articles: ArticleApi[]
  fruit_name?: string
  recommended_article: {
    ID: number
    post_name: string
    post_title: string
    permalink: string
  } | null
}

export type HealthToolInfoApiBmi = {
  age: number
  created_at: string
  gender: number
  height: number
  id: string
  objective: number
  score: number
  score_level: number
  updated_at: string
  weight: number
}

export type HealthToolInfoApiBmr = {
  active_level: number
  age: number
  created_at: string
  gender: number
  height: number
  id: string
  kcal: number
  kcal_level: number
  objective: number
  updated_at: string
  weight: number
}

export type HealthWomanObjective = 2 | 3 | 4
export type HealthToolInfoApiOvulation = {
  created_at: string
  cycle_length_days: number
  fertile_from_date: string
  fertile_to_date: string
  first_day: string
  id: string
  menstrual_from_date: string
  menstrual_to_date: string
  objective: HealthWomanObjective
  ovulation_date: string
  period_duration_days: number
  updated_at: string
}

export type HealthToolInfoApiPwg = {
  bmi: number
  check_for: 'self' | 'other'
  created_at: string
  height: number
  id: string
  is_having_twin: boolean
  pregnancy_week: number
  suggest_weight_max: number
  suggest_weight_min: number
  updated_at: string
  weight: number
  weight_assessment: 'underweight' | 'healthy' | 'overweight'
  weight_before_pregnancy: number
}

export type HealthDueDateMethod = 1 | 2 | 3
export type HealthToolInfoApiDueDate = {
  id: string
  created_at: string
  updated_at: string
  account_id: number
  method: HealthDueDateMethod
  conception_date: string
  cycle_length_days: null | number
  days_transfer: null | number
  email: null | string
  first_day: null | string
  ivf_transfer_date: null | string
}

export type HealthToolInfoApi = {
  activity_level: string
  age: number
  gender: number
  height: number | null
  name: string

  bmis: HealthToolInfoApiBmi[] | null

  bmrs: HealthToolInfoApiBmr[] | null

  ovulation: HealthToolInfoApiOvulation[] | null

  pregnancies: HealthToolInfoApiPwg[] | null

  due_dates: HealthToolInfoApiDueDate[] | null
  heart_rates: unknown | null
}

export type DataTrackingType = {
  dataEventCategory: string
  dataEventAction: string
  dataEventLabel: string
}

export type DataTrackingTypeHTMLElement = {
  'data-event-category': string
  'data-event-action': string
  'data-event-label': string
}

export type BabyPoopPage = {
  title: string
  englishName: string
  description: string
  featuredImage: string
  isSponsored: boolean
  sponsor: {
    title: string
    link: string
    logo: string
  } | null
  sponsorBanner: {
    desktopImage: string
    mobileImage: string
    showSponsorBanner: boolean
    videoBlock?: {
      title: string
      listVideo: {
        title: string
        videoUrl: string
      }[]
    } | null
  } | null
  disclaimer: string | null
  information:
    | {
        title: string
        description: string
      }[]
    | null
  toolGuideline: {
    title: string
    description: string
  } | null
  care: unknown[]
  reviewedBy: {
    ID: number
    userId: number
    formerRole: string
    role: string
    slug: string
    displayName: string
    description: string
    profileUrl: string
    avatar: string
    avatarThumbnail: string
    turnOffProfile: boolean
    specialty: string
    organisation: string
    organisationUrl: string
  } | null
  reviewedDate: string
  content: string
  result: {
    name: string
    image: string
    color: 'green' | 'red' | 'yellow'
    textInput: string
    titleResult: string
    description: string
    whatShouldIDo: string
    faqs: {
      title: string
      description: string
    }[]
    recommendArticles: Article[]
  }[]
  isLeadgen: boolean
  metaTag: string
}

export type QuestionType = {
  id: number
  question: string
  questionHint: string
}
export type AnswerType = {
  id: number
  answer: string
}
export type QuestionResponseType = {
  id: number
  value: string
}
export type AdditionalQuestionType = {
  question: QuestionType
  answers: AnswerType[]
}
export type AdditionalQuestionResponseType = {
  question: QuestionResponseType
  answers: QuestionResponseType[]
}
export type AdditionalQuestionFieldType = {
  question: QuestionType
  questionHint?: string
  answers: Array<AnswerType>
  hasAnother?: boolean
  isMulti?: boolean
  otherInputPlaceholder?: string
  additionalQuestions?: AdditionalQuestionResponseType[]
}
export type HealthtoolReviewer = {
  reviewer: string
  date: string
  link: string
  avatar: string
}

export type AdditionalInfo = {
  title: string
  description: string
  image: string
  ctaUrl: string
  ctaTitle: string
}
export type CommonHealthtoolInfoApi = {
  title: string
  description: string
  list_questions: Array<AdditionalQuestionFieldTypeApi>
  sponsor_banner: AdditionalInfoApi
} & CollectingDataFormApi
export type CommonHealthtoolInfo = {
  title: string
  description: string
  listQuestions: Array<AdditionalQuestionFieldType>
  additionalInfo: AdditionalInfo
} & CollectingDataForm

export type AdditionalQuestionFieldTypeApi = {
  question: QuestionTypeApi
  answers: Array<AnswerType>
  has_another: boolean
  select_multi: boolean
  placeholder: string
}
export type AdditionalInfoApi = {
  image: string
  further_result: {
    title: string
    Content: string
    link: {
      url: string
      title: string
    }
  }
}
export type QuestionTypeApi = {
  id: number
  question: string
  tooltip_question: string
}

export type CollectingDataFormApi = {
  collecting_types: CollectingDataFormType
  collecting_data: {
    campaigns: any
    banner: {
      desktop: string
      mobile: string
    }
  }
  phone_verification: PhoneVerifyType
  is_skippable: boolean
}

export enum CollectingDataFormType {
  PHONE_ONLY = 'phone-verification',
  LEADGEN_FORM = 'leadgen-form',
  NONE = 'none', // FE default value
}
export enum PhoneVerifyType {
  SMS = 'phone-sms',
  WHATSAPP = 'whatsapp',
}

export type CollectingDataForm = {
  collectingTypes: CollectingDataFormType
  collectingData: {
    campaigns: any
    banner: {
      desktop: string
      mobile: string
    }
  }
  phoneVerification: PhoneVerifyType
  isSkippable: boolean
}
